<template>
  <div class="ourbusiness">
    <carousel
        :nav = true
        :centerMode = true
        :autoPlay = true
        :playSpeed = 8000
        :dtImg = banner
	/>
      <div class="bg-grays-100 text-grays-400 w-full h-20 overflow-x-scroll">
            <ul class="flex justify-center items-center h-full lg:px-32">
                <li 
                    v-for="data, i in compData" 
                    :key="i"
                    class="px-2 mx-3 capitalize font-pop text-xs lg:text-sm text-grays-400 font-semibold w-40 h-full flex items-center cursor-pointer duration-100 justify-center border-b-4 border-solid border-grays-200 hover:border-primary hover:text-primary flex-wrap"
                    :class="{'activeTab' : i == activeTab }" 
                    @click="tabNav(i)" 
                >
                    {{ data.name }}
                </li>
            </ul>
      </div>
      <div v-if="compData == null">Loading..</div>
      <div class="max-w-screen-xl mx-auto mb-32 lg:px-32 px-2 text-left" v-if="compData != null">
            <div class="my-6">
                <!-- <h5 v-if="lang == 'id'" class="text-lg">Bisnis Kami</h5>
                <h5 v-else class="text-lg">Our Business</h5> -->
                <h3 class="font-semibold text-xl">{{ whichTab.name }}</h3>
            </div>
            <div>
                <carouselOur
                    :nav = true
                    :centerMode = true
                    :autoPlay = true
                    :playSpeed = 8000
                    :dtImg = whichTab.image
                />
             
                <!-- <h5 class="text-xs">{{ whichTab.imgCaption }}</h5> -->
            </div>
            <div v-if="lang == 'id'" class="my-10 buss_profile" v-html="whichTab.profile"></div>
            <div v-else class="my-10 buss_profile" v-html="whichTab.profile_eng"></div>
            <div>
                <h4 v-if="lang == 'id'" class="mb-4 font-semibold text-center text-lg">Pelanggan Kami</h4>
                <h4 v-else class="mb-4 font-semibold text-center text-lg">Our Customer</h4>
                <div v-if="whichTab.customer.length <= 4" class="pt-4 flex justify-center">
                    <img v-for="data in whichTab.customer" :src="data.image" alt="" class="h-32 mx-4">
                </div>
                <carouselSponsor
                    :itemsToShow="6"
                    :infiniteScroll="true"
                    :nav ="true"
                    :centerMode="true"
                    :autoPlay="false"
                    :playSpeed="8000"
                    :dtImg = whichTab.customer
                    class="w-full h-56 mx-auto"
                    v-else
                />
            </div>
      </div>
  </div>
</template>

<script>
import carousel from "@/components/carousel.vue";
import carouselOur from "@/components/carouselOur.vue";
import carouselSponsor from "@/components/carouselSponsor.vue";
import axios from 'axios'
import { mapState } from 'vuex';

export default {
    components: {
        carousel,
        carouselOur,
        carouselSponsor
    },
    data() {
        return {
            activeTab: 0,
            compData: null,
            image:[]
        }
    },

    beforeMount(){
        this.getData()
    },

    methods: {
        tabNav(i) {
            // console.log(this.tabMenu[i]);
            this.activeTab = i
        },
        async getData(){
            axios.get('https://api.dharmagroup.co.id/api/company/datatable?perpage=20&page=1', { 'headers': { 'Authorization': 'Bearer 71063a7eab248181eafe665bf4ae346f-sFqsGThLIgEL9Kvjo0NZInsTou2NMoexYTnPwVjXAgV1K2scDuW58jBBy53' } })
                .then(response => {
                    // Handle response
                    this.compData = response.data.data
                })
                .catch(err => {
                    // Handle errors
                    console.error(err);
                });
            }
        },
    computed: {
        ...mapState({
			lang: state => state.home.lang ? state.home.lang :'id'
        }),
        whichTab() {
            return this.compData[this.activeTab]
        },
        banner(){
			return this.$store.getters['image/imgWtFilter']('business_banner')
		}
    }
}
</script>

<style>
    .activeTab{
        @apply bg-primary text-white border-primary hover:text-grays-50;
    }
</style>